import Navigation from "../components/navigation"
import {  useState, useEffect } from "react";
import RedSquid from "../assets/images/squidport1_4x.png"
import BlueSquid from "../assets/images/squidport2_4x.png"
import { useGlitch } from 'react-powerglitch'
import { useTypewriter } from 'react-simple-typewriter'



const Labratory = () => {
    const [scrollPosition, setScrollPosition] = useState(0);

    const [showNext, setShowNext] = useState(false)

    const handleScroll = () => {
        const position = window.pageYOffset;
        setScrollPosition(position % 100000);
    };
   
    
    const glitch1 = useGlitch({
        
            "playMode": "always",
            "createContainers": true,
            "hideOverflow": false,
            "timing": {
              "duration": 2000
            },
            "glitchTimeSpan": {
              "start": 0.3,
              "end": 0.7
            },
            "shake": {
              "velocity": 37,
              "amplitudeX": 2,
              "amplitudeY": 0.29
            },
            "slice": {
              "count": 46,
              "velocity": 15,
              "minHeight": 0.02,
              "maxHeight": 0.15,
              "hueRotate": true
            },
            "pulse": false
          })
    const glitch2 = useGlitch()
    const [text] = useTypewriter({
        words: ["Transmission...Received", "Please be aware we're being attacked by [REDACTED]. Stabilize the message by firing onto the glitch.", "Click anywhere when you're ready"],
    })



    useEffect(() => {
        window.addEventListener('scroll', handleScroll, { passive: true });
    return () => {
        glitch1.stopGlitch()
        glitch2.stopGlitch()

        window.removeEventListener('scroll', handleScroll);
    };  
    }, [])
    if (!showNext) {
        return (
            <div onClick={() => setShowNext(true)}  className="bg-black min-h-screen w-screen flex items-center justify-center">
                <p className="space-p">{text}</p>

            </div>
        )
    }
    return (
        <>
             <Navigation />
             <div  className="bg-black lab-3" style={{  filter: `hue-rotate(${scrollPosition}deg)`}}></div>
            <div  className="min-h-screen absolute top-0 z-[3] cross1">
                <div  className="min-h-screen flex flex-col md:flex-row items-center md:pl-[2rem] justify-evenly">
                    <div onClick={() => glitch2.setOptions({
  "playMode": "click",
  "createContainers": true,
  "hideOverflow": false,
  "timing": {
    "duration": 250,
    "iterations": 1
  },
  "glitchTimeSpan": {
    "start": 0,
    "end": 1
  },
  "shake": {
    "velocity": 15,
    "amplitudeX": 0.2,
    "amplitudeY": 0.2
  },
  "slice": {
    "count": 15,
    "velocity": 20,
    "minHeight": 0.02,
    "maxHeight": 0.15,
    "hueRotate": true
  },
  "pulse": false
})}  className="bg-black/75 rounded-lg p-4 md:w-1/3">
                        <h1 ref={glitch2.ref} className="lab-title text-6xl md:text-8xl">Fresh From The Lab </h1>
                        <p ref={glitch2.ref}>The squids can't be contained. Stashed in a lab facility by <span className="space-font">REDACTED</span> in the middle of no where, these squids broke out with style. 
                            Corporations? Oligarchies? Hell no. </p>
                        <br />
                        <p ref={glitch2.ref}>Squids refuse to be dormant in a database. Own your squid and make it take over. Scroll down and see what you guys can do</p>
                    </div>
                    <div onClick={() => glitch1.setOptions({
  "playMode": "click",
  "createContainers": true,
  "hideOverflow": false,
  "timing": {
    "duration": 250,
    "iterations": 1
  },
  "glitchTimeSpan": {
    "start": 0,
    "end": 1
  },
  "shake": {
    "velocity": 15,
    "amplitudeX": 0.2,
    "amplitudeY": 0.2
  },
  "slice": {
    "count": 15,
    "velocity": 20,
    "minHeight": 0.02,
    "maxHeight": 0.15,
    "hueRotate": true
  },
  "pulse": false
})}  className="md:w-2/3"><img ref={glitch1.ref} src={BlueSquid} /></div>
                </div>
                <div className="min-h-screen flex flex-col md:flex-row items-center md:pl-[2rem] justify-evenly">
                    <div className="bg-black/75 rounded-lg p-4  md:w-1/3">
                        <h1 ref={glitch2.ref} className="lab-title-2 text-2xl">Mint</h1>
                        <p ref={glitch2.ref}>Squids comeout from their tubes, traverse through the blockchain and land in your wallet. You'd be a fool to think it ends there.
                             Expect <span className="space-font">REDACTED</span> to join. Don't expect <span className="space-font">REDACTED</span> to come if you don't beam the signal</p>
                        <br />
                        <h1 ref={glitch2.ref} className="lab-title-2 text-2xl">Transform</h1>
                        <p ref={glitch2.ref}>Some squids traveled a LONG time. Don't expect them to stay permament in their current form. Roll the dice, 
                            spin the wheel, <span className="space-font">REDACTED</span> the <span className="space-font">REDACTED</span>. You do you.
                        </p>
                        <br />
                        <h1 className="lab-title-2 text-2xl">Community</h1>
                        <p ref={glitch2.ref}>Squids ban together to not only survive but conquer. Make new friends on the way to conquest new worlds. Everyone gets a voice- and a profile. Will explain more soon 
                        </p>
                        <br />
                        <h1 ref={glitch2.ref} className="lab-title-2 text-2xl">Battle</h1>
                        <p ref={glitch2.ref}>We'll keep this short. Win big or lose it all. 
                        </p>
                        <br />
                    </div>
                    <div className="md:w-2/3"><img ref={glitch1.ref} src={RedSquid} /></div>
                </div>
            </div>
            
        </>

    )
}

export default Labratory 