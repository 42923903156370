import Navigation from "../components/navigation"

const Manifesto = () => {
    return (
        <>
            <div className="darkness"></div>
            <div className="space-cropped bg-bottom   md:bg-center bg-contain md:bg-cover w-[100vw] "></div>
            <div className="background-ctn space bg-top md:bg-center">
                <Navigation />
                <div className="flex pt-[30%] md:pt-[10%] md:pl-[6%] flex-col  md:w-[40vw] p-4" >
                    <h1 className="space-font header text-4xl md:text-6xl mb-4 space-title">LAB <br /> MANiFESTO</h1>
                    <>
                        <p className="mb-4 space-body"> 
                            A bright light shoots past in the sky <br />
                            Beaming across the stars<br />
                            Unrelenting in its force<br />
                            There are no walls here<br />
                            In fact they are broken<br />
                            Accepting anyone into our community<br />
                            Our strength will only increase<br />
                        </p>
                        <p className="space-body">
                            This is only the beginning  <br />
                            The takeover is imminent<br />
                            Nothing will stop us<br />
                            Join our brigade<br />
                            Leave your mark in the depths of Space <br />
                        </p>
                    </>
                </div>
            </div>
         </>
    )
}

export default Manifesto