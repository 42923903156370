import Navigation from "../components/navigation"
import { useTypewriter } from 'react-simple-typewriter'

const Marketplace = () => {
    const [text] = useTypewriter({
        words: ["Hmmmm... You're early. Come back when I have stock."],
    })
    return (
        <div className="background-ctn-2 marketplace bg-left md:bg-center">
            <Navigation />
            <div className="absolute bottom-[2.5vh] left-1/2 transform -translate-x-1/2 h-[10%] md:h-[20vh] bg-black/[.8] w-[80vw] flex justify-center items-center">
                    <p className="space-p">{text}</p>
            </div>

        </div>
    )
}

export default Marketplace