import { Link } from "react-router-dom"
import { useState } from "react"
import { useAccount, useConnect, useEnsName } from 'wagmi'
import { InjectedConnector } from 'wagmi/connectors/injected'


import Discord from "./Discord"
import Twitter from "./Twitter"


const Navigation = () => {
    const [show, setShow] = useState(false)
    const { address, isConnected } = useAccount()
    const { data: ensName } = useEnsName({ address })
    const { connect } = useConnect({
      connector: new InjectedConnector(),
    })

    const routes = (
        <>
            <Link to={'/'}><li>HOME</li></Link>
            <Link to={'/LABRATORY'}><li>THE LABRATORY</li></Link>
            {/* <Link to={'/'}><li>BLUEPRiNT</li></Link> */}
            <Link to={'/BLACKMARKET'}><li>BLACK MARKET</li></Link>
            <Link to={'/MANiFESTO'}><li>LAB MANiFESTO</li></Link>
            <Link to={'GALLERY'} ><li>GALLERY</li></Link>
            <li className="blocked">SQUADS</li>
            <li className="blocked">STAKiNG</li>
            <li onClick={() => connect()} className=" cursor-pointer">{isConnected ? "CONNECTED" : 'CONNECT'}</li>

            <li className="flex justify-center items-center cursor-pointer"><a href={''}><Discord height={15} /> </a></li>
                    <li className="flex justify-center items-center cursor-pointer"><a href={'https://twitter.com/Squids_xyz'}> <Twitter height={15} /> </a></li>
        </>
    )
    return (
        <>
            <ul className='nav hidden md:flex lg:flex justify-between w-[100vw]'>
                <div className="flex">
                    {/* <Link to={'/'}><li>Home</li></Link>
                    <Link to={'/features'}><li>Features</li></Link>
                    <Link to={'/faq'}><li>FAQ</li></Link> */}
                </div>
                <div className="flex w-[90%] justify-end">
                    {routes}
                </div>
            </ul>
            <div onClick={() => setShow(!show)} className="burger md:hidden">
                <span />
                <span />
                <span />
            </div>
            {show && <>
                <div onClick={() => setShow(false)} className="overlay"></div>
                <ul className="sidebar">
                    {routes}
                </ul>
            </>}
           
        </>
    )
}

export default Navigation