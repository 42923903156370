import Navigation from "../components/navigation";
import { useContractRead, useContractWrite, usePrepareContractWrite } from 'wagmi'
import SquidABI from "../assets/abi/squid.json"

import Unrevealed from "../assets/images/UnrevealedSquid.gif"

const CONTRACT_SQUID = "0x6BD7b092a67414b78a42950E0a2209a8702E2614"




const Gallery = () => {

    const squidData = useContractRead({
        address: CONTRACT_SQUID,
        abi: SquidABI,
        functionName: 'totalSupply',
    })
    let mintedSquid = Math.floor((squidData.data) / 10 ** 18)
    console.log(mintedSquid)
    const renderSquids = () => {
        let arr = []
        for (let i = 1; i < 2023; i++) {
            arr.push(i)
        }
        return (
            <div className="flex justify-center flex-wrap gap-6 pt-20 px-4">
                {arr.map((i, index) => <div><img className="w-[300px] h-[300px]" src={`/images/${i}.png`} alt="Unrevealed" /><p className=" pt-3 text-center">{index + 1}</p></div>)}
            </div>
        )
        
    }

    return (
        <div className="background-ctn outside bg-left md:bg-center">
        <Navigation />
        <div className="w-full">
            {renderSquids()}
        </div>
</div>
    )
   
}

export default Gallery
