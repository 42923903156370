import Navigation from "../components/navigation"
import SquidTool from "../assets/images/SquidAccessory.gif"
import SquidDark from "../assets/images/SquidDark.gif"
import InkcessoryABI from "../assets/abi/inkcessory.json"
import { ethers } from "ethers"
import SquidABI from "../assets/abi/squid.json"
import { useContractRead, useContractWrite, usePrepareContractWrite } from 'wagmi'
import { useAccount, useConnect} from 'wagmi'
import { InjectedConnector } from 'wagmi/connectors/injected'
import {useState} from 'react'



const MAX_INK_SUPPLY = 3031
const CONTRACT_INK = "0x7FD455640f35b2dB548C747c873237D07D753dB0"

const MAX_SQUID_SUPPLY = 2222
const CONTRACT_SQUID = "0x6BD7b092a67414b78a42950E0a2209a8702E2614"


const connectWallet = async () => {
    if (window.ethereum) {
        try {
          const addressArray = await window.ethereum.request({
            method: "eth_requestAccounts",
          });
          const obj = {
            status: "Success",
            address: addressArray[0],
          };
          return obj;
        } catch (err) {
          return {
            address: "",
            status: err.message,
          };
        }
      } else {
        return {
          address: "",
          status: "Please install a web3 wallet"
        };
      }
}

const Home = () => {

    const [inkQty, setInkQty] = useState(1)
    const [squidQty, setSquidQty] = useState(1)
    const { connect } = useConnect({
        connector: new InjectedConnector(),
      })
    const inkData = useContractRead({
        address: CONTRACT_INK,
        abi: InkcessoryABI,
        functionName: 'totalSupply',
    })
    const inkDataPaused = useContractRead({
    address: CONTRACT_INK,
    abi: InkcessoryABI,
    functionName: 'paused',
    })

    const squidData = useContractRead({
        address: CONTRACT_SQUID,
        abi: SquidABI,
        functionName: 'totalSupply',
    })
    const squidDataPaused = useContractRead({
    address: CONTRACT_SQUID,
    abi: SquidABI,
    functionName: 'paused',
    })

    const { address, isConnected } = useAccount()


    const inkcessoryPrepare = usePrepareContractWrite({
        address: CONTRACT_INK,
        abi: InkcessoryABI,
        functionName: 'mint',
        args: [inkQty],
        chainId: 7700,
        overrides: {
            value: ethers.utils.parseEther('0'),
            from: address,
        }
    })
    const inkcessoryMint = useContractWrite(inkcessoryPrepare.config)

    const squidsTotalCost = 12 * squidQty
    console.log(squidsTotalCost, "tc")
    const squidPrepare = usePrepareContractWrite({
        address: CONTRACT_SQUID,
        abi: SquidABI,
        functionName: 'mint',
        args: [squidQty],
        chainId: 7700,
        overrides: {
            value: ethers.utils.parseEther(squidsTotalCost.toString()),
            from: address,
        }
    })
    const squidMint = useContractWrite(squidPrepare.config)

    let mintedInk = Math.floor((inkData.data) / 10 ** 18)
    let tokensLeftInk = MAX_INK_SUPPLY - mintedInk
    console.log(tokensLeftInk + " left. Act quick")
    let mintedSquid = Math.floor((squidData.data) / 10 ** 18)
    let tokensLeftSquid = MAX_SQUID_SUPPLY - mintedSquid
    console.log(tokensLeftSquid + " left. Act quick")
    return (
        <div className="background-ctn outside bg-left md:bg-center">
            <Navigation />
            <div className="flex justify-center items-center min-h-[100vh]">
                {isConnected ? <div style={{ backgroundColor: "#222222f5" }} className="flex w-[90vw] md:w-[50vw] items-center flex-col p-4">
                    <p className="text-2xl mb-4">Choose your mint</p>
                    <div className="flex items-center justify-center h-full ">
                        <div className="w-1/2 flex flex-col items-center  h-full justify-between cool-shadow">
                            <div className="w-60 h-60 flex items-center"><img className="w-40 h-40" src={SquidTool} alt="Squid Accessory"/></div>
                            <p className="text-center mb-2">What's this? A tool? Accessory? Doesn't matter, we like free stuff</p>
                            <div className="flex"><input type={'number'} min={1} max={3} value={inkQty} onChange={(e) => setInkQty(e.currentTarget.value)}  className="px-6 pr-2 mr-2 bg-[#181818] mb-4 rounded text-white text-center"  /><p className="text-white">Max 3 (Free)</p></div>
                            {inkQty < 4 && inkQty > 0 && <button  className="mint-btn px-2 py-1 rounded ">Sold Out</button>}
                        </div>
                        <div className="w-1/2 flex flex-col items-center h-full justify-between cool-shadow">
                            <div className="w-60 h-60"><img className="w-[210px] h-[210px] rounded"  src={SquidDark} alt="Squid Accessory"/></div>
                            <p className="text-center mb-2">Get your squid. Let's start this journey. And we'll conquer through the cosmos</p>

                            <div className="flex-1"></div>
                            <div className="flex"><input type={'number'} min={1} max={6} value={squidQty} onChange={(e) => setSquidQty(e.currentTarget.value)}  className="px-6 pr-2 mr-2 bg-[#181818] mb-4 rounded text-white text-center"  /><p className="text-white">Max 6 (12 Canto each)</p></div>
                            {squidQty > 0 && squidQty < 7 && <button onClick={() => squidMint?.write?.()} className="mint-btn px-2 py-1 rounded ">Mint Now</button>}
                        </div>
                    </div>
                </div> : <div onClick={() => connect()} style={{ backgroundColor: "#222222f5" }} className="flex w-[90vw] md:w-[50vw] items-center flex-col p-4 cursor-pointer mint-btn"><p>Connect to mint</p></div>}
            </div> 
        </div>
    )
}

export default Home