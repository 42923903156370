import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import {
  createBrowserRouter,
  RouterProvider,
  Route,
} from "react-router-dom";
import "animate.css/animate.min.css";
// import {canto} from "@wagmi/chains"



import Home from './pages/Home';
import Manifesto from './pages/Manifesto';
import Marketplace from './pages/Marketplace';
import Labratory from './pages/Labratory';
import Navigation from './components/navigation';
import { WagmiConfig, createClient,  } from 'wagmi'
import { publicProvider } from 'wagmi/providers/public'
import { configureChains } from '@wagmi/core'
import Gallery from './pages/Gallery';



const canto = {
    id: 7700,
    name: 'CANTO',
    network: 'CANTO',
    
    rpcUrls: {
      public: { http: ['https://canto.slingshot.finance/'] },
      default: { http: ['https://canto.slingshot.finance/'] },
    },
    blockExplorers: {
      etherscan: { name: 'evm.explorer.canto', url: 'https://evm.explorer.canto.io/' },
      default: { name: 'evm.explorer.canto', url: 'https://evm.explorer.canto.io/' },
    },
}
 
const { chains, provider, webSocketProvider } = configureChains(
  [canto],
  [publicProvider()],
)

const client = createClient({
  autoConnect: true,
  provider,
  webSocketProvider,
})



const root = ReactDOM.createRoot(document.getElementById('root'));

const router = createBrowserRouter([
  {
    path: "/",
    element: <Home />,
  },
  {
    path: "MANiFesto",
    element: <Manifesto />
  },
  {
    path: "LABRATORY",
    element: <Labratory />
  },
  {
    path:"BLACKMARKET",
    element: <Marketplace />
  },
  {
    path:"GALLERY",
    element: <Gallery />
  }
]);

root.render(
  <WagmiConfig client={client}>

    <RouterProvider router={router} />
    </WagmiConfig>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
